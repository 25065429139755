import axios from '@/axios.js'

const state = () => ({
    laporans: []
})

const mutations = {
    SET_LAPORANS (state, payload) {
      state.laporans = payload
    },
    ADD_LAPORAN (state, item) {
      state.laporans.unshift(item)
    },
    UPDATE_LAPORAN (state, laporan) {
      const laporanIndex = state.laporans.findIndex((p) => p.id === laporan.id)
      Object.assign(state.laporans[laporanIndex], laporan)
    },
    REMOVE_LAPORAN (state, itemId) {
      const ItemIndex = state.laporans.findIndex((p) => p.id === itemId)
      state.laporans.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchLaporan ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/laporan`)
        .then((response) => {
          commit('SET_LAPORANS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addLaporan ({ commit }, laporan ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', laporan)
        .then((response) => {
          commit('ADD_LAPORAN', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateLaporan ({ commit }, laporan) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${laporan.id}`, laporan)
        .then((response) => {
            commit('UPDATE_LAPORAN', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateFormLaporan ({ commit }, laporan) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update-form`, laporan)
        .then((response) => {
            commit('UPDATE_LAPORAN', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeLaporan ({ commit }, laporan) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${laporan.id}`)
        .then((response) => {
            commit('REMOVE_LAPORAN', laporan.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}