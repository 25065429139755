
<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} LAPORAN</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div class="p-6">
        
        <div class="vx-row mt-5">
          <div class="vx-col w-full">
            <vs-input label="Laporan" v-model="dataLaporan" class="w-full" name="item-laporan" v-validate="'required'" />
            <span class="text-danger text-sm" v-show="errors.has('item-laporan')">{{ errors.first('item-laporan') }}</span>
          </div>
          <div class="vx-col w-full mt-5">
            <template v-if="Attachment">
              
              <template v-if="upImg">
                <img :src="getImage('/term/'+Attachment)" alt="" class="responsive">
              </template>
              <template v-else>
                <img :src="Attachment" alt="" class="responsive">
              </template>
              
              <div class="modify-img flex justify-between mt-5 mb-5">
                <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
                <vs-button class="w-full" @click="$refs.updateImgInput.click();upImg = false">Update Image</vs-button>
              </div>
            </template>

            <div class="upload-img" v-if="!Attachment">
              <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
              <vs-button @click="$refs.uploadImgInput.click(); upImg = false" class="w-full">Upload Image</vs-button>
            </div>
          </div>
        </div>

        <!--
        <vs-row class="mt-5">
          <vs-col vs-align="center" vs-w="6">
            <vs-select
              label="Group"
              v-model="dataGroup"
              >
                <vs-select-item value="0" text="Tidak ada"/>
                <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listLaporan"/>
            </vs-select>
          </vs-col>

          <vs-col vs-align="center" vs-w="6">
            <vs-select
              label="Parent"
              v-model="dataParent"
              >
                <vs-select-item value="0" text="Tidak ada"/>
                <vs-select-item :key="itemx.id" :value="itemx.id" :text="itemx.name" v-for="itemx in listLaporan"/>
            </vs-select>
          </vs-col>
        </vs-row>
        -->

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">Submit</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {

      dataId: null,
      dataLaporan: null,
      dataGroup: 0,
      dataParent: 0,

      Attachment : null,
      upImg: true,

      listLaporan: [],

      settings: {
        maxScrollbarLength: 80,
        wheelSpeed: .80
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      
      let configs = {
        headers: {
          'Authorization': 'Bearer ' + this.user.api_token,
        },
      }
      this.axios.get('/term-list/laporan', configs)
      .then((response) => {
        let {data} = response.data
        this.listLaporan = data
      })
      .catch((error) => {
        let data = error.data
        this.listLaporan = data
      })

      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        const { id, name, term_group, parent, gambar  } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataLaporan = name
        this.dataGroup = term_group
        this.dataParent = parent
        this.Attachment = gambar
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          this.$validator.reset()
          this.initValues()
        }
      }
    },
    isFormValid () {
      return !this.errors.any() && this.dataLaporan && this.Attachment
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    ...mapGetters({
      user : 'auth/user',
    }),
  },
  mounted(){
    
  },
  methods: {
    submitData () {
      this.$validator.validateAll().then(result => {
        if (result) {
          let formData = new FormData()
          formData.set('id', this.dataId)
          formData.set('name', this.dataLaporan)
          formData.set('term_group', 0)
          formData.set('parent', 0)

          formData.set('taxonomy', 'laporan')

          if (this.upImg === false) { 
            formData.append('gambar', this.Attachment)
          }

          if (this.dataId !== null && this.dataId >= 0) {
            this.$store.dispatch('laporan/updateFormLaporan', formData)
          } else {
            this.$store.dispatch('laporan/addLaporan', formData)
          }
          this.$emit('closeSidebar')
          this.initValues()
          this.upImg = true
        }
      })
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.Attachment = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    initValues () {
      this.dataId = null
      this.dataLaporan = null
      this.dataGroup = 0
      this.dataParent = 0
      this.Attachment = null
      this.upImg = true
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 450px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
